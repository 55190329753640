<template>
  <report-section
    title="氧减统计"
    :reportModeType="reportDetailModel.reportModeType"
    :isHideBar="isHideAHIBar"
    :odiIndex="reportDetailModel.odiIndex"
  >
    <template v-slot:content>
      <div>
        <table class="table">
          <thead>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">氧减</td>
              <td class="table-row-item table-row-item-bold">≥4%</td>
              <td class="table-row-item table-row-item-bold">≥3%</td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">次数</td>
              <td class="table-row-item">
                {{ reportDetailModel.downTimesOver4Str }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.downTimesOver3Str }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">指数</td>
              <td class="table-row-item">
                {{ reportDetailModel.downIndexOver4Str }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.downIndexOver3Str }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  computed: {
    isHideAHIBar() {
      return (
        this.reportDetailModel.xyReportTemplateType ==
        SPO2_REPORT_TEMPLATE.河南省儿童医院耳鼻喉科
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #707070;

  &-row {
    display: flex;
    justify-content: space-evenly;

    &-item {
      color: black;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #707070;
      width: 300px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bold {
        font-weight: 600;
      }
    }
  }
}
</style>
