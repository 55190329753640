<template>
    <div class="report-bottom">
        <div class="report-bottom-content" :class="{ 'report-bottom-content-thin': false }">
            <p class="report-bottom-content-info">注：本报告仅供临床医生参考！</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        model: Object
    },

    data() {
        return {

        }
    },

    computed: {
    }
};
</script>

<style lang="scss" scoped>
.report-bottom {

    &-content {
        font-size: rem(12);
        width: 100%;
        height: rem(30);
        margin-top: rem(15);

        &-info {
            border-bottom: 1px solid #eeeeee;
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: center;
        }
    }
}
</style>