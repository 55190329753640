<template>
  <report-section title="用户信息" style="position: relative">
    <template v-slot:title-right>
      <edit-button style="position: absolute; right: 0" :inputIsEditable="isEditable" @onEdit="onEdit"
        @onSave="onSave"></edit-button>
    </template>
    <template v-slot:content>
      <div class="section-user-info">
        <div v-if="isEditable" class="section-user-info-editable">
          <div class="section-user-info-editable-item">
            <key-unit-input title="姓名" v-model="name"></key-unit-input>
            <key-unit-input title="性别" v-model="gender"></key-unit-input>
            <!-- <key-unit-input title="年龄" v-model="age"></key-unit-input> -->
            <key-unit-date-picker title="生日" v-model="birthday" :picker-options="pickerOptions"/>
          </div>
          <div class="section-user-info-editable-item section-user-info-editable-item-last">
            <key-unit-input title="身高" v-model="height"></key-unit-input>
            <key-unit-input title="体重" v-model="weight"></key-unit-input>
            <key-unit-input v-if="isShowCaseNumber" title="病例号" v-model="medicalRecordNumber"></key-unit-input>
          </div>
          <div v-if="isShowUserInfoRow3" class="section-user-info-editable-item section-user-info-editable-item-last">
            <key-unit-input title="床号" v-model="bedNumber"></key-unit-input>
            <key-unit-input title="病例号" v-model="medicalRecordNumber"></key-unit-input>
            <key-unit-input title="主管医生" v-model="doctorName"></key-unit-input>
          </div>
        </div>
        <div v-else class="section-user-info-not-editable">
          <div class="section-user-info-not-editable-item">
            <key-value-unit title="姓名" :value="reportDetailModel.nameStr"></key-value-unit>
            <key-value-unit title="性别" :value="reportDetailModel.genderStr"></key-value-unit>
            <key-value-unit title="年龄" :value="reportDetailModel.ageStr"></key-value-unit>
            <div v-if="isShowCaseNumber" style="min-width: 250px"></div>
          </div>
          <div class="section-user-info-not-editable-item">
            <key-value-unit title="身高" :value="reportDetailModel.heightStr"></key-value-unit>
            <key-value-unit title="体重" :value="reportDetailModel.weightStr"></key-value-unit>
            <key-value-unit title="BMI" :value="reportDetailModel.bmiStr"></key-value-unit>
            <key-value-unit v-if="isShowCaseNumber" title="病案号"
              :value="reportDetailModel.medicalRecordNumber"></key-value-unit>
          </div>
          <div v-if="isShowUserInfoRow3" class="section-user-info-not-editable-item">
            <key-value-unit title="床号" :value="reportDetailModel.bedNumber"></key-value-unit>
            <key-value-unit title="病例号" :value="reportDetailModel.medicalRecordNumber"></key-value-unit>
            <key-value-unit title="主管医生" :value="reportDetailModel.doctorName"></key-value-unit>
          </div>
        </div>
      </div>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import EditButton from "@c/report-detail/single-blood-oxygen-report/common/edit-button.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
import KeyUnitInput from "@c/report-detail/single-blood-oxygen-report/common/key-unit/key-unit-input.vue";
import { findKey } from "@js/object-tool.js";
import { computeBMI, computeSex } from "@js/common-tool.js";
import KeyUnitDatePicker from "@c/report-detail/single-blood-oxygen-report/common/key-unit/key-unit-date-picker.vue";
import dayjs from "dayjs";
export default {
  components: {
    ReportSection,
    EditButton,
    KeyValueUnit,
    KeyUnitInput,
    KeyUnitDatePicker,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      isEditable: false,
      name: undefined,
      gender: undefined,
      age: undefined,
      birthday: undefined,
      height: undefined,
      weight: undefined,
      bedNumber: undefined,
      medicalRecordNumber: undefined,
      doctorName: undefined,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  computed: {
    isShowUserInfoRow3() {
      return (
        this.reportDetailModel.xyReportTemplateType ==
        SPO2_REPORT_TEMPLATE.广东医科大学附属第二医院
      );
    },

    isShowCaseNumber() {
      return (
        this.reportDetailModel.xyReportTemplateType ==
        SPO2_REPORT_TEMPLATE.河南省儿童医院耳鼻喉科
      );
    },
  },

  created() {
    this.setupUserInfo();
  },

  watch: {
    reportDetailModel() {
      this.setupUserInfo();
    },
  },

  methods: {
    onEdit() {
      this.isEditable = true;
    },

    async onSave() {
      try {
        //名字input上已做限制
        this.validateGender();
        this.validateBirthday();
        this.validateValue(this.height, "身高");
        this.validateValue(this.weight, "体重");
        this.saveUserInfo();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async saveUserInfo() {
      const bmi = computeBMI(this.height, this.weight, 1);
      const sex = computeSex(this.gender);
      const params = {
        reportId: this.reportDetailModel.reportId,
        name: this.name,
        sex: sex,
        // age: this.age,
        birthday: dayjs(this.birthday).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        weight: this.weight,
        height: this.height,
        bmi: bmi,
        bedNumber: this.bedNumber,
        medicalRecordNumber: this.medicalRecordNumber,
        doctorName: this.doctorName,
      };
      try {
        await this.$api.saveBloodOxygenReportUserInfo(params);
        this.isEditable = false;
        this.$emit("onEditUserInfoSucceed");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    setupUserInfo() {
      this.name = this.reportDetailModel.reportUser.name;
      this.age = this.reportDetailModel.reportUser.age;
      this.gender =
        findKey(USER_GENDER, this.reportDetailModel.reportUser.sex) ?? "";
      this.height = this.reportDetailModel.reportUser.height;
      this.weight = this.reportDetailModel.reportUser.weight;
      this.bedNumber = this.reportDetailModel.reportUser.bedNumber;
      this.medicalRecordNumber =
        this.reportDetailModel.reportUser.medicalRecordNumber;
      this.doctorName = this.reportDetailModel.reportUser.doctorName;
      this.birthday = this.reportDetailModel.reportUser.birthday;
    },

    validateGender() {
      try {
        //为空不做判断
        if (this.gender == "") {
          return;
        }
        if (this.gender != "男" && this.gender != "女") {
          throw "";
        }
      } catch (error) {
        throw "性别只能填男或女";
      }
    },

    validateBirthday() {
      try {
        //为空不做判断
        if (!this.birthday) {
          throw "生日不能为空";
        }
        //判断日期格式
        if (!dayjs(this.birthday).isValid()) {
          throw "日期格式错误";
        }
      } catch (error) {
        throw error;
      }
    },

    validateAge() {
      try {
        //为空不做判断
        if (this.age == "") {
          return;
        }
        //判断整数
        if (this.age.indexOf(".") != -1) {
          throw "不是整数";
        }
        //判断[0,200]
        const ageNumber = Number(this.age);
        if (isNaN(ageNumber)) {
          throw "不是数字";
        }
        if (0 < ageNumber && ageNumber > 200) {
          throw "不属于0-200";
        }
      } catch (error) {
        throw "患者年龄是0-200的整数";
      }
    },

    validateValue(value, name) {
      try {
        //为空不做判断
        if (value == "") {
          return;
        }
        //判断最多一位小数
        if (value.split(".")[1] && value.split(".")[1].length > 1) {
          throw "最多一位小数";
        }
        //判断[0,300]
        const valueNumber = Number(value);
        if (isNaN(valueNumber) || 0 > valueNumber || valueNumber > 300) {
          throw "应为0-300";
        }
      } catch (error) {
        throw `患者${name}${error}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.section-user-info {
  display: flex;

  .key-unit-input {
    width: 140px;
    margin-top: 20px;
  }

  &-not-editable {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .key-value-unit {
      min-width: 250px;
      // width: 25%;
    }
  }

  &-editable {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;

      &-last {
        margin-top: 20px;
      }
    }

    .key-unit {
      margin-right: 20px;
    }
  }
}
</style>
