import { findKey } from "@js/object-tool.js";
import { DateTool } from "@js/date-tool.js";
import dayjs from "dayjs";

window.USER_GENDER = {
    男: 1,
    女: 2,
}

window.SLEEP_EPRIOD = {
    清醒期: 0,
    眼动期: 2,
    浅睡期: 3,
    深睡期: 4,
}

class ReportDetailModel {

    constructor(reportDetail) {
        this.reportDetail = reportDetail.reportDetail//为了引用方便
        this.reportUser = reportDetail.reportUser //为了引用方便
        //用户信息
        this.nameStr = placeholderText(this.reportUser.name)
        this.genderStr = placeholderText(findKey(USER_GENDER, this.reportUser.sex))
        this.heightStr = placeholderText(this.reportUser.height, "cm")
        this.weightStr = placeholderText(this.reportUser.weight, "kg")
        this.bmiStr = placeholderText(this.reportUser.bmi)
        this.bedNumber = placeholderText(this.reportUser.bedNumber)
        this.medicalRecordNumber = placeholderText(this.reportUser.medicalRecordNumber)
        this.doctorName = placeholderText(this.reportUser.doctorName)
        this.ageStr = this._initAgeStr()

        //儿童模式
        this.reportModeType = reportDetail.modeType //0成人1儿童（同睡眠）

        //模版类型
        this.xyReportTemplateType = reportDetail.xyReportTemplateType

        //基础信息
        this.reportId = this.reportDetail.id
        this.startTimeStr = placeholderText(reportDetail.startTime)
        this.endTimeStr = placeholderText(reportDetail.endTime)
        this.totalSleepTimeStr = placeholderText(reportDetail.totalTime)
        this.sopReportHeaders = reportDetail.sopReportHeaders
        this.sopReportSubTitle = reportDetail.sopReportSubTitle
        this.sopReportLogo = reportDetail.sopReportLogo

        //血氧饱和度统计
        this.averageBloodOxygen = placeholderText(this.reportDetail.avgO2, "%")
        this.minBloodOxygen = placeholderText(this.reportDetail.minO2, "%")
        this.bloodOxygenUnder95TimeStr = placeholderText(this.reportDetail.secondsUnder95)
        this.bloodOxygenUnder90TimeStr = placeholderText(this.reportDetail.secondsUnder90)
        this.bloodOxygenUnder85TimeStr = placeholderText(this.reportDetail.secondsUnder85)
        this.bloodOxygenUnder80TimeStr = placeholderText(this.reportDetail.secondsUnder80)
        this.bloodOxygenUnder70TimeStr = placeholderText(this.reportDetail.secondsUnder70)
        this.bloodOxygenUnder60TimeStr = placeholderText(this.reportDetail.secondsUnder60)
        this.bloodOxygenUnder95Ratio = this.reportDetail.shareUnder95
        this.bloodOxygenUnder90Ratio = this.reportDetail.shareUnder90
        this.bloodOxygenUnder85Ratio = this.reportDetail.shareUnder85
        this.bloodOxygenUnder80Ratio = this.reportDetail.shareUnder80
        this.bloodOxygenUnder70Ratio = this.reportDetail.shareUnder70
        this.bloodOxygenUnder60Ratio = this.reportDetail.shareUnder60
        this.bloodOxygenUnder95RatioStr = placeholderText(this.reportDetail.shareUnder95?.toFixed(3), "%")
        this.bloodOxygenUnder90RatioStr = placeholderText(this.reportDetail.shareUnder90?.toFixed(3), "%")
        this.bloodOxygenUnder85RatioStr = placeholderText(this.reportDetail.shareUnder85?.toFixed(3), "%")
        this.bloodOxygenUnder80RatioStr = placeholderText(this.reportDetail.shareUnder80?.toFixed(3), "%")
        this.bloodOxygenUnder70RatioStr = placeholderText(this.reportDetail.shareUnder70?.toFixed(3), "%")
        this.bloodOxygenUnder60RatioStr = placeholderText(this.reportDetail.shareUnder60?.toFixed(3), "%")

        //氧减统计
        this.downTimesOver4Str = placeholderText(this.reportDetail.downTimes4?.toFixed(0))
        this.downIndexOver4Str = placeholderText(this.reportDetail.downIndex4)
        this.downTimesOver3Str = placeholderText(this.reportDetail.downTimes?.toFixed(0))
        this.downIndexOver3Str = placeholderText(this.reportDetail.downIndex)
        this.odiIndex = this.reportDetail.downIndex

        //氧减事件统计
        this.oxygenMinusEventLess100TimesStr = placeholderText(this.reportDetail.odi3Less100Cnt?.toFixed(0))
        this.oxygenMinusEventLess95TimesStr = placeholderText(this.reportDetail.odi3Less95Cnt?.toFixed(0))
        this.oxygenMinusEventLess90TimesStr = placeholderText(this.reportDetail.odi3Less90Cnt?.toFixed(0))
        this.oxygenMinusEventLess85TimesStr = placeholderText(this.reportDetail.odi3Less85Cnt?.toFixed(0))
        this.oxygenMinusEventLess80TimesStr = placeholderText(this.reportDetail.odi3Less80Cnt?.toFixed(0))
        this.oxygenMinusEventLess75TimesStr = placeholderText(this.reportDetail.odi3Less75Cnt?.toFixed(0))
        this.oxygenMinusEventLess70TimesStr = placeholderText(this.reportDetail.odi3Less70Cnt?.toFixed(0))
        this.oxygenMinusEventLess65TimesStr = placeholderText(this.reportDetail.odi3Less65Cnt?.toFixed(0))
        this.oxygenMinusEventLess60TimesStr = placeholderText(this.reportDetail.odi3Less60Cnt?.toFixed(0))

        this.oxygenMinusEventLess100Ratio = this.reportDetail.odi3Less100Percent
        this.oxygenMinusEventLess95Ratio = this.reportDetail.odi3Less95Percent
        this.oxygenMinusEventLess90Ratio = this.reportDetail.odi3Less90Percent
        this.oxygenMinusEventLess85Ratio = this.reportDetail.odi3Less85Percent
        this.oxygenMinusEventLess80Ratio = this.reportDetail.odi3Less80Percent
        this.oxygenMinusEventLess75Ratio = this.reportDetail.odi3Less75Percent
        this.oxygenMinusEventLess70Ratio = this.reportDetail.odi3Less70Percent
        this.oxygenMinusEventLess65Ratio = this.reportDetail.odi3Less65Percent
        this.oxygenMinusEventLess60Ratio = this.reportDetail.odi3Less60Percent

        this.oxygenMinusEventLess100RatioStr = placeholderText(this.reportDetail.odi3Less100Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess95RatioStr = placeholderText(this.reportDetail.odi3Less95Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess90RatioStr = placeholderText(this.reportDetail.odi3Less90Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess85RatioStr = placeholderText(this.reportDetail.odi3Less85Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess80RatioStr = placeholderText(this.reportDetail.odi3Less80Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess75RatioStr = placeholderText(this.reportDetail.odi3Less75Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess70RatioStr = placeholderText(this.reportDetail.odi3Less70Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess65RatioStr = placeholderText(this.reportDetail.odi3Less65Percent?.toFixed(1), "%")
        this.oxygenMinusEventLess60RatioStr = placeholderText(this.reportDetail.odi3Less60Percent?.toFixed(1), "%")

        //氧减时间统计
        this.oxygenMinusTimeLess10TimesStr = placeholderText(this.reportDetail.odi3Less10sCnt?.toFixed(0))
        this.oxygenMinusTimeLess20TimesStr = placeholderText(this.reportDetail.odi3Less20sCnt?.toFixed(0))
        this.oxygenMinusTimeLess30TimesStr = placeholderText(this.reportDetail.odi3Less30sCnt?.toFixed(0))
        this.oxygenMinusTimeLess40TimesStr = placeholderText(this.reportDetail.odi3Less40sCnt?.toFixed(0))
        this.oxygenMinusTimeLess50TimesStr = placeholderText(this.reportDetail.odi3Less50sCnt?.toFixed(0))
        this.oxygenMinusTimeLess60TimesStr = placeholderText(this.reportDetail.odi3Less60sCnt?.toFixed(0))
        this.oxygenMinusTimeMore60TimesStr = placeholderText(this.reportDetail.odi3Longer60sCnt?.toFixed(0))

        this.oxygenMinusTimeLess10Ratio = this.reportDetail.odi3Less10sPercent
        this.oxygenMinusTimeLess20Ratio = this.reportDetail.odi3Less20sPercent
        this.oxygenMinusTimeLess30Ratio = this.reportDetail.odi3Less30sPercent
        this.oxygenMinusTimeLess40Ratio = this.reportDetail.odi3Less40sPercent
        this.oxygenMinusTimeLess50Ratio = this.reportDetail.odi3Less50sPercent
        this.oxygenMinusTimeLess60Ratio = this.reportDetail.odi3Less60sPercent
        this.oxygenMinusTimeMore60Ratio = this.reportDetail.odi3Longer60sPercent

        this.oxygenMinusTimeLess10RatioStr = placeholderText(this.reportDetail.odi3Less10sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeLess20RatioStr = placeholderText(this.reportDetail.odi3Less20sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeLess30RatioStr = placeholderText(this.reportDetail.odi3Less30sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeLess40RatioStr = placeholderText(this.reportDetail.odi3Less40sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeLess50RatioStr = placeholderText(this.reportDetail.odi3Less50sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeLess60RatioStr = placeholderText(this.reportDetail.odi3Less60sPercent?.toFixed(1), "%")
        this.oxygenMinusTimeMore60RatioStr = placeholderText(this.reportDetail.odi3Longer60sPercent?.toFixed(1), "%")

        //脉率统计
        this.averagePulseStr = placeholderText(this.reportDetail.avgPr, "bpm")
        this.maxPulseStr = placeholderText(this.reportDetail.maxPr, "bpm")
        this.minPulseStr = placeholderText(this.reportDetail.minPr, "bpm")

        //HRV
        this.sdnn = placeholderText(this.reportDetail.sdnn)

        //睡眠分期统计
        this.totalHoursStr = placeholderText(DateTool.formatSeconds(this.reportDetail.sleepMinutes * 60))
        this.lightHoursStr = placeholderText(DateTool.formatSeconds(this.reportDetail.lightMinutes * 60))
        this.deepHoursStr = placeholderText(DateTool.formatSeconds(this.reportDetail.deepMinutes * 60))
        this.remHoursStr = placeholderText(DateTool.formatSeconds(this.reportDetail.remMinutes * 60))

        this.totalTimeStr = placeholderText(this.reportDetail.sleepMinutes?.toFixed(0), "分钟")
        this.wakeMinutesStr = placeholderText(this.reportDetail.wakeMinutes?.toFixed(0), "分钟")
        this.remMinutesStr = placeholderText(this.reportDetail.remMinutes?.toFixed(0), "分钟")
        this.lightMinutesStr = placeholderText(this.reportDetail.lightMinutes?.toFixed(0), "分钟")
        this.deepMinutesStr = placeholderText(this.reportDetail.deepMinutes?.toFixed(0), "分钟")

        this.wakeMinutesTestPercentStr = placeholderText(this.reportDetail.wakeMinutesTestPercent?.toFixed(1), "%")
        this.remMinutesTestPercentStr = placeholderText(this.reportDetail.remMinutesTestPercent?.toFixed(1), "%")
        this.lightMinutesTestPercentStr = placeholderText(this.reportDetail.lightMinutesTestPercent?.toFixed(1), "%")
        this.deepMinutesTestPercentStr = placeholderText(this.reportDetail.deepMinutesTestPercent?.toFixed(1), "%")

        this.wakeMinutesSleepPercentStr = placeholderText(null)
        this.remMinutesSleepPercentStr = placeholderText(this.reportDetail.remMinutesSleepPercent?.toFixed(1), "%")
        this.lightMinutesSleepPercentStr = placeholderText(this.reportDetail.lightMinutesSleepPercent?.toFixed(1), "%")
        this.deepMinutesSleepPercentStr = placeholderText(this.reportDetail.deepMinutesSleepPercent?.toFixed(1), "%")

        this.remMinutesSleepPercent = this.reportDetail.remMinutesSleepPercent
        this.lightMinutesSleepPercent = this.reportDetail.lightMinutesSleepPercent
        this.deepMinutesSleepPercent = this.reportDetail.deepMinutesSleepPercent

        //趋势图
        this.bloodOxygenTrandArray = this._initBloodOxygenTrandArray()
        this.pulseTrandArray = this._initPulseTrandArray()
        this.periodTrandArray = this._initPeriodTrandArray()



        //意见和建议
        //为了设置的粗细和字体正常显示，需要在reset.css文件中把b标签的"font: inherit"注释掉
        this.suggestContent = this.reportDetail.suggestContent
        this.doctorSignature = this.reportDetail.doctorSignature //没有不显示
        this.signatureDate = this.reportDetail.signatureDate //没有不显示



    }

    _initBloodOxygenTrandArray() {
        const bloodOxygenTrandArray = []
        this.reportDetail.o2Arr.forEach((bloodOxygen, index) => {
            const timestamp = this.reportDetail.startAt + index * 1000
            const bloodOxygenTrand = [
                timestamp,
                bloodOxygen
            ]
            bloodOxygenTrandArray.push(bloodOxygenTrand)
        });
        this.removeEmptyElements(bloodOxygenTrandArray)
        return bloodOxygenTrandArray
    }

    _initPulseTrandArray() {
        const pulseTrandArray = []
        this.reportDetail.prArr.forEach((pulse, index) => {
            const timestamp = this.reportDetail.startAt + index * 1000
            const pulseTrand = [
                timestamp,
                pulse
            ]
            pulseTrandArray.push(pulseTrand)
        });
        this.removeEmptyElements(pulseTrandArray)
        return pulseTrandArray
    }

    _initAgeStr() {
        if (!this.reportUser.birthday) {
            return placeholderText(this.reportUser.age)
        }
        const birthDay = dayjs(this.reportUser.birthday)
        const baseDate = dayjs(this.reportDetail.startTime).toDate()
        return DateTool.calculateAgeWithBaseDate(birthDay.year(), birthDay.month() + 1, birthDay.date(), baseDate);
    }

    removeEmptyElements(arr) {
        // 去掉头部空元素
        while (arr.length > 0 && arr[0][1] === 0) {
            arr.shift();
        }
        // 去掉尾部空元素
        while (arr.length > 0 && arr[arr.length - 1][1] === 0) {
            arr.pop();
        }
        return arr;
    }

    _initPeriodTrandArray() {
        const periodTrandArray = []
        //rawStageArr
        const rawStageArr = this.reportDetail.stageArr.map((item, index) => {
            let value
            switch (item) {
                case SLEEP_EPRIOD.清醒期:
                    value = 4
                    break
                case SLEEP_EPRIOD.眼动期:
                    value = 3
                    break
                case SLEEP_EPRIOD.浅睡期:
                    value = 2
                    break
                case SLEEP_EPRIOD.深睡期:
                    value = 1
                    break
                default:
                    value = null;
                    break
            }
            return { value, index }
        })
        //stageArr
        removeEmptyElements(rawStageArr)
        //periodTrandArray
        rawStageArr.forEach((item) => {
            const timestamp = this.reportDetail.startAt + item.index * 60000
            const periodTrand = [
                timestamp,
                item.value
            ]
            periodTrandArray.push(periodTrand)
        });
        return periodTrandArray

        function removeEmptyElements(arr) {
            // 去掉头部空元素
            while (arr.length > 0 && arr[0].value === null) {
                arr.shift();
            }
            // 去掉尾部空元素
            while (arr.length > 0 && arr[arr.length - 1].value === null) {
                arr.pop();
            }
            return arr;
        }
    }


}

export { ReportDetailModel }